import React from "react";
import styled from "@emotion/styled";

import Section from "@components/Section";
import Sidebar from "@components/Sidebar";
import Seo from "@components/SEO";
import Layout from "@components/Layout";
import Paginator from "@components/Navigation/Navigation.Paginator";

import ArticlesHero from "../sections/articles/Articles.Hero";
import ArticlesList from "../sections/articles/Articles.List";

import { Template } from "@types";
import mediaqueries from "@styles/media";

const ArticlesPage: Template = ({ location, pageContext }) => {
  const articles = pageContext.group;
  const authors = pageContext.additionalContext.authors;
  const categories = pageContext.categories;
  const tags = pageContext.tags;

  return (
    <Layout>
      <Seo pathname={location.pathname} />
      <ArticlesHero authors={authors} />
      <Section narrow>
        <Row>
          <main>
            <ArticlesList articles={articles} />
            <ArticlesPaginator show={pageContext.pageCount > 1}>
              <Paginator {...pageContext} />
            </ArticlesPaginator>
          </main>
          <aside>
            <Sidebar categories={categories} tags={tags} authors={authors}></Sidebar>
          </aside>
        </Row>
      </Section>
      {/* <ArticlesGradient /> */}
    </Layout>
  );
};

export default ArticlesPage;

const ArticlesPaginator = styled.div<{ show: boolean }>`
  ${(p) => p.show && `margin-top: 95px;`}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  main {
    width: 70%;
  }
  aside {
    width: calc(30% - 30px);
    margin-left: 30px;
  }
  ${mediaqueries.desktop`
    main,
    aside {
      width: 100%;
      margin: 0;
    }
  `};
`;
