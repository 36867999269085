import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import Bio from "@components/Bio";

const Sidebar: React.FC<{ authors; categories; tags }> = (props) => {
  const { authors, categories, tags } = props;
  const featuredAuthor = authors.find((author) => author.featured);
  return (
    <>
      <Section>
        <h3>About</h3>
        <Bio author={featuredAuthor} />
      </Section>
      <Section>
        <h3>Category</h3>
        <CategoryList>
          {categories.map((category) => (
            <li key={category.slug} className={category.parent ? "child" : ""}>
              <Link to={`/taxonomy?category=${category.slug}`}>
                {category.name}
              </Link>
            </li>
          ))}
        </CategoryList>
      </Section>
      <Section>
        <h3>Tags</h3>
        <TagList>
          {tags.map((tag) => (
            <li key={tag}>
              <Link to={`/taxonomy?tag=${tag}`}>{tag}</Link>
            </li>
          ))}
        </TagList>
      </Section>
    </>
  );
};

export default Sidebar;

const Section = styled.section`
  margin: 20px 0 40px;
  h3 {
    color: ${(p) => p.theme.colors.primary};
    margin: 0 0 20px;
  }
`;

const CategoryList = styled.ul`
  li {
    list-style: none;
    padding-bottom: 13px;
    margin-bottom: 13px;
    border-bottom: 1px solid #e3e3e3;
    &.child {
      margin-left: 30px;
    }
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
  a {
    color: ${(p) => p.theme.colors.primary};
    transition: all 0.2s;
    &:hover {
      color: ${(p) => p.theme.colors.accent};
    }
  }
`;

const TagList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  li {
    list-style: none;
    margin-right: 10px;
    margin-bottom: 15px;
  }
  a {
    color: ${(p) => p.theme.colors.primary};
    background: ${(p) => p.theme.colors.hover};
    font-weight: 700;
    border-radius: 15px;
    padding: 5px 12px;
    transition: all 0.2s;
    &:hover {
      color: ${(p) => p.theme.colors.background};
      background: ${(p) => p.theme.colors.accent};
    }
  }
`;
